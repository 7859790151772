import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_shops/";

export default {
	fetch: async function(callback,no) {
        await axios.get(url + "?no="+no).then(response => callback(response));
    },
	fetchList: async function(callback,method=[],query) {

		await axios.get(
			url
			+"?asc=" + method.asc
			+"&limit1=" + method.limit1
			+"&limit2=" + method.limit2
			+"&fieldColumn=" + method.fieldColumn
			+"&field=" + method.field
			, {
				params: query
			}).then(response => callback(response));
	},
};
