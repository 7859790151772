import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_fcms/";

export default {
	fetchList: async function(
		callback,
		contents,
		contents_bloc,
		method=[],
	) {
        await axios.get(
		url
			+ "?contents="+contents
			+"&contents_bloc=" + contents_bloc
			+"&asc=" + method.asc
			+"&limit1=" + method.limit1
			+"&limit2=" + method.limit2
			+"&fieldColumn=" + method.fieldColumn
			+"&field=" + method.field
		).then(response => callback(response));
    }
};
