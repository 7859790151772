#3c3939<template>
  <v-app>

    <v-app-bar
      app
      color="#3c3939"
      dark
      flat
    >
      <v-container class="pa-0" fluid v-if="this.$route.name != 'makuake'">
        <v-row align="center" justify="center">
          <v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
            <div class="d-flex align-center justify-space-between flex-sm-wrap">

            <div style="height:64px; margin-left:-4px;">
				<router-link :to="{path:'/'}">
				<img src="./assets/logo.svg" style="height:64px;">
				</router-link>
			</div>
              <v-spacer></v-spacer>
              <div class="sp-hide">
				<router-link  to="/register"><a class="mr-4">新規会員登録</a></router-link>
				<router-link  to="/login"><a class="mr-4">マイページ</a></router-link>
              </div>
              <v-app-bar-nav-icon @click.stop="drawer = !drawer"><i class="fas fa-bars"></i></v-app-bar-nav-icon>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
		v-model="drawer"
		class="drawer"

		fixed
		temporary
		right
		dark
		color="black"
    >
      <v-list dense>
        <v-list-item>
			<v-list-item-content>
				<v-list-item-title>
					<router-link :to="{path:'/'}">ホーム</router-link>
				</v-list-item-title>
			</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
				<router-link  to="/register">新規会員登録</router-link>
			</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
				<router-link  to="/mypage/login">マイページ</router-link>
			</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-divider class="mb-12"></v-divider>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
				<router-link  to="/sales-policy">特定商取引法に基づく表記</router-link>
			</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
          <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
				<router-link  to="/privacy-policy">PRIVACY POLICY</router-link>
			</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>


    <v-main>
		<router-view />
	</v-main>

<v-footer
    class="justify-center body-2 text-center pa-4 black" dark>

  <div>
	<br>
	CHEFLOGは、新型コロナの影響から立ち上がる飲食店を応援します！
	シェフがお薦めする予約の取れない人気の名店をご予約できます！<br><br>
	<small>Copyright &copy; {{ new Date().getFullYear() }} CHEFLOG. All Rights Reserved.</small>
  </div>
  </v-footer>

  </v-app>
</template>

<script>
	export default {
		data() {
			return {
				drawer: false,

			}
		},
	}
</script>

<style scoped>
@media (max-width: 599px) {
	.sp-hide {
		display: none;
	}
}


.drawer a {
	color: white !important;
	text-decoration: none !important;
}

.v-toolbar__content {
  padding: 4px 16px 4px 12px!important;
}
header {
  overflow:hidden;
}
header a {
  color:#fff;
	text-decoration: none !important;
}
header i {
  font-size:24px;
  overflow:hidden;
}

</style>

