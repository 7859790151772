import Vue from 'vue'
import App from './App.vue'
import * as filters from './filters' // global filters
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

// register global utility filters
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
	VueAxios,
	axios
}).$mount('#app')
