import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import Home from '../views/coming.vue'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	routes: [{

		path: '/',
		name: 'Home',
		component: Home
	},
		/** _________________________________________________________________ リストページ **/
		{
			path: '/list/:category/',
			name: 'List',
			component: () => import('../views/List.vue'),
			props: true,
		},
		/** _________________________________________________________________ 店舗ページ **/
		{
			path: "/shop/:no(\\d+)", //数値のみにマッチ
			name: "shop",
			component: () => import('../views/Shop.vue'),
			//props: true
			props: route => ({ no: Number(route.params.no) })
		},
		/** _________________________________________________________________ 会員登録 **/
		{	// メール登録
			path: "/register/",
			component: () => import('@/views/register/mailaddress.vue'),
			name: 'register',
			props: true,
		},
		{	// ユーザ登録
			path: "/register/user/:para",
			component: () => import('@/views/register/user.vue'),
			name: 'register',
			props: true,
		},
		/** _________________________________________________________________ マクアケ会員登録 **/
		{	// メール登録
			path: "/makuake/",
			component: () => import('@/views/makuakeRegister/mailaddress.vue'),
			name: 'makuake',
			props: true,
		},
		{	// ユーザ登録
			path: "/makuake/user/:para",
			component: () => import('@/views/makuakeRegister/user.vue'),
			name: 'makuake',
			props: true,
		},
		/** _________________________________________________________________ 予約画面 **/
		{	// 予約画面
			path: "/reservation/",
			component: () => import('@/views/reservation/index.vue'),
			name: 'reservation',
			props: true,
		},
		/** _________________________________________________________________ 特定商 **/
		{
			path: '/sales-policy',
			name: 'SalesPolicy',
			component: () => import('../views/SalesPolicy.vue')
		},
		/** _________________________________________________________________ プライバシーポリシー **/
		{
			path: '/privacy-policy',
			name: 'PrivacyPolicy',
			component: () => import('../views/PrivacyPolicy.vue')
		},

		/** _________________________________________________________________ 通販ページ TODO 削除 **/
		{
			path: '/detail2',
			name: 'Detail2',
			component: () => import('../views/Detail2.vue')
		},
		/** _________________________________________________________________ ログイン **/
		{
			path: '/login',
			name: 'login',
			component: () => import('../views/login/index.vue')
		},
		/** _________________________________________________________________ マイページ **/
		{
			path: '/mypage/:modePara?',
			name: 'mypage',
			component: () => import('../views/mypage/index.vue'),
			props: true,

		},
		/** _________________________________________________________________ LINE BOT **/
		{
			path: '/linebot/register',
			name: 'linebot register',
			component: () => import('../views/linebot/register.vue')
		},
		/** _________________________________________________________________ 店舗管理画面 **/
		{	// 予約詳細画面
			path: '/shop_manage/reserve/:shopNum/:num',
			component: () => import('../views/shop_manage/reserve/detail.vue'),
			name: 'shop_manage',
			props:true,
		},
		/** _________________________________________________________________ カートシステム **/
		{	// カート
			path: '/cart/:modePara?',
			name: 'cart',
			component: () => import('../views/cart/index.vue'),
			props:true,
		},

		/** _________________________________________________________________ 商品情報 **/
		{	// 商品一覧

			path: "/product/:shopId",
			name: "product",
			component: () => import('../views/product/index.vue'),
			props: true

		},
		{	// 商品詳細
			path: "/product/detail/:shopId/:itemNum",
			name: 'product_detail',
			component: () => import('../views/product/detail.vue'),
			props: true,
		},

	]
});


export default router
