import Moment from 'moment'

/**
 * 日付に変換
 * @param date
 * @returns {string}
 */
export function dateFormat(date) {
	return Moment(date).format('YYYY-MM-DD');
}

/**
 * 日本の日付に変換
 * @param date
 * @returns {string}
 */
export function japaneseDate(date) {
	if(!date){return}

	Moment.locale("ja")
	return Moment(date).format('YYYY年MM月DD日(ddd)')
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
	return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * TODO nl2br
 * @param data
 * @returns {*}
 */
export function nl2br(data) {
	return data
}

/**
 * arrayNewLine
 * @param array
 * @returns {*}
 */
export function arrayNewLine(array) {

	if(!array) { return null}

	return array.join('\n')

}

