<template>
	<div class="home">

		<v-card
				color="black"
				dark
				tile
		>

			<v-carousel
					height="auto"
					cycle
			>
				<v-carousel-item
						v-for="(image,i) in eyecatchs"
						:key="i"
				>
					<div v-if="image.contents_parts1">
						<router-link :to="image.contents_parts1">
							<v-img
									contain
									:src="image.contents_pic1"
									width="100%"
                  eager
							/>
						</router-link>
					</div>
					<div v-else>
						<v-img
								contain
								:src="image.contents_pic1"
								width="100%"
                eager
						/>
					</div>
				</v-carousel-item>
			</v-carousel>
			<v-card-text
					class="text-center justify-center">
				有名シェフが通うお店の予約サイト
			</v-card-text>
		</v-card>


		<v-container class="pa-0" fluid>
			<v-card
					outlined
					tile
					class="border-none shop-card">
				<v-card-text>
<!--					<v-row align="center" justify="center">
						<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
							<v-select
                  v-model="searchCategory"
									:items="genres"
									filled
									background-color="#e3e3e4"
									label="ジャンルで探す"
									class="justify-center select-menu"
									item-color="black"
							></v-select>
						</v-col>
					</v-row>-->
					<v-row align="center" justify="center">
            <!--	<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-select
                    :items="shops"
                    filled
                    background-color="#e3e3e4"
                    label="店舗を探す"
                    class="justify-center select-menu"
                    item-color="black"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-select
                    :items="areas"
                    filled
                    background-color="#e3e3e4"
                    text-color="#000"
                    label="エリアで探す"
                    class="justify-center select-menu"
                    item-color="black"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-select
                    :items="genres"
                    filled
                    background-color="#e3e3e4"
                    label="ジャンルで探す"
                    class="justify-center select-menu"
                    item-color="black"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-select
                    :items="prices"
                    filled
                    background-color="#e3e3e4"
                    label="価格帯で探す"
                    class="justify-center select-menu"
                    item-color="black"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-menu
                    v-model="reserveDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="ご来店日"

                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="reserveDate = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
                <v-select
                    :items="reserveTime"
                    label="ご来店時間"
                ></v-select>
              </v-col>
  -->
<!--						<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
							<v-btn
									color="#e60044"
									dark
									depressed
									tile
									x-large
									block
									to="list"
							>
								条件で検索
							</v-btn>
						</v-col>-->
					</v-row>



					<v-row align="center" justify="center" class="btn-reserve">
<!--						<v-col xs="6" sm="6" md="5" lg="4" xl="4">
							<v-btn
									color="#e60044"
									dark
									depressed
									tile
									x-large
									block
									to="list"
							>
								日時を指定して<br>予約する
							</v-btn>
						</v-col>-->
<!--						<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
							<v-btn
									color="#e60044"
									dark
									depressed
									tile
									x-large
									block
									:to="'list/'+searchCategory"
							>
								今すぐ行けるお店を<br>予約する
							</v-btn>
						</v-col>-->
					</v-row>

					<v-row align="center" justify="center" class="btn-reserve">
						<v-col sm="12" md="10" lg="8" xl="8" class="text-center">
							ご予約には会員登録が必要となります。
						</v-col>
					</v-row>

					<v-row align="center" justify="center" class="btn-register">
						<v-col sm="6" md="3" lg="2" xl="2">
							<v-btn
									black
									outlined
									depressed
									tile
									block
									to="/register"
							>
								新規会員登録はこちら
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-row align="center" justify="center">
				<v-col sm="12" md="12" lg="12" xl="12" class="pb-0">
					<v-card
							color="black"
							dark
							tile
					>
						<v-card-title
								class="text-center justify-center">今すぐ行ける店舗
						</v-card-title>

					</v-card>
				</v-col>
			</v-row>
      <v-row align="center" justify="center">
        <v-col sm="12" md="10" lg="8" xl="8">
      <v-row align="baseline" justify="start">

			<!--<div v-for="shop in shops" :key="shop.no">-->
        <v-col sm="12" md="4" lg="4" xl="4" v-for="shop in shops" :key="shop.no">
			<!--<v-row align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="2" class="pt-0">
					<router-link :to="'/shop/'+shop.no">
					<v-img
							contain
							:src="shop.image"
							width="100%"
					/>
					</router-link>
				</v-col>

			</v-row>-->

			<v-card
					outlined
					tile
					class="border-none shop-card">
			<!--	<v-row align="center" justify="center">
					<v-col sm="12" md="10" lg="8" xl="2">-->
            <router-link :to="'/shop/'+shop.no">
              <v-img
                  contain
                  :src="shop.image"
                  width="100%"
              />
            </router-link>
						<v-card-subtitle class="font-weight-bold">
							<v-chip
									class="mr-2 mb-2"
									color="black"
									outlined
									label
									small
									disabled

							>
								エリア
							</v-chip>
							{{shop.shop_area}}
							<br>
							<v-chip
									class="mr-2 mb-2"
									color="black"
									outlined
									label
									small
									disabled
							>
								ジャンル
							</v-chip>
							{{shop.shop_genre}}
						</v-card-subtitle>

						<div class="shop-title">
							<v-card-title class="font-weight-bold">
								{{shop.name}}
							</v-card-title>

							<v-card-subtitle>
								{{shop.furigana}}
							</v-card-subtitle>

							<v-card-subtitle class="abrid-add">
								〒{{shop.zip}} {{shop.city}}{{shop.address1}}{{shop.address2}}<br>
								{{shop.shop_address_note}}
							</v-card-subtitle>
							<v-btn
									tile
									depressed
									color="white"
									class="icon-map"
							>
								<a :href="shop.shop_googlemap" target="_blank">
									<i class="fas fa-map-marker-alt mr-1"></i>Google Map
								</a>
							</v-btn>
						</div>
						<v-card-subtitle>
							<div v-if="shop.shop_lunch_price">
								<span style="margin-right:1em;">ランチ</span>
								<span
										class="ml-2 title">&yen;{{shop.shop_lunch_price}}〜</span>(税サ別 / 人)<br>
							</div>
							<div v-if="shop.shop_dinner_price">
								ディナー
								<span class="ml-2 title">&yen;{{shop.shop_dinner_price}}〜</span>(税サ別 / 人)
							</div>
						</v-card-subtitle>
						<v-card-text class="abrid-text">
							{{shop.shop_explanation}}
						</v-card-text>
            <v-card-text class="btn-reserve">
                  <v-btn
                      color="#e60044"
                      dark
                      depressed
                      tile
                      block
                      :to="`./shop/${shop.no}`"
                  >
                    お店を予約
                  </v-btn>
                  <v-btn
                      v-if="shop.shop_ec == 1"
                      color="#e60044"
                      dark
                      depressed
                      tile
                      block
                      :to="`/product/${shop.no}`"
                  >
                    ご自宅への配送
                  </v-btn>


              <!--					<v-row align="center" justify="center" class="btn-reserve">
                          <v-col sm="6" md="5" lg="4" xl="4">
                            <v-btn
                                color="#e60044"
                                dark
                                depressed
                                tile
                                block
                                to="./detail2"
                            >
                              通販
                            </v-btn>
                          </v-col>
                          <v-col sm="6" md="5" lg="4" xl="4">
                            <v-btn
                                color="#e60044"
                                dark
                                depressed
                                tile
                            >
                              テイクアウト
                            </v-btn>
                          </v-col>
                        </v-row>-->
            </v-card-text>
					<!--</v-col>
				</v-row>-->


			</v-card>
        </v-col>
			<!--</div>-->
      </v-row>
        </v-col>
      </v-row>
		</v-container>


	</div>
</template>

<script>
	import fcms from "@/api/fcms.js";
	import shops from "@/api/shops.js";

	export default {
		data() {
			return {
				// fcms
				fcms: [],

				//fcms アイキャッチ
				eyecatchs:[],
				eyecatchsMethods:[],

				//fcms 店舗情報並び順
				shopfield:null,
				shopFieldMethods:[],
        shopQuery:{
          memberType:1,
        },

				// 検索処理
				searchCategory:'all',

				//shop情報
				shops: null,
				shopMethods:[],

				drawer: false,
				date: new Date().toISOString().substr(0, 10),
				reserveDate: false,
				areas: ['渋谷区', '品川区', '港区', '中央区'],
				genres: ['全て','イタリアン','フレンチ','和食','鍋・ラーメン会席'],
				prices: ['１万未満','15,000円～','20,000円～'],
				reserveTime: ['11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']

			}
		},
		methods :{
			/** ____________________________________________________________________ API fcmsから情報を取得  */
			async setFcms(info) {
				this.fcms = info.data;


				// 取得エラー
				if(this.fcms.result != 'success') {
					this.result = 'error'
				}

				// アイキャッチの取得
				if(this.fcms[0].contents == 2 && this.fcms[0].contents_bloc == 1) {
					this.eyecatchs = this.fcms
				}

				// 店の並び順
				if(this.fcms[0].contents == 2 && this.fcms[0].contents_bloc == 3) {
					this.shopfield = this.fcms[0].contents_parts1

					// 店舗情報の取得
					this.$nextTick(function(){
						this.shopMethods.fieldColumn 	= 'no'
						this.shopMethods.field 			  = this.shopfield
						shops.fetchList(this.setInfo_shops, this.shopMethods,this.shopQuery);
					})
				}

			},

			/** ____________________________________________________________________ API 店舗情報から情報を取得  */
			async setInfo_shops(info) {
				this.shops = info.data;
			},
		},
		mounted() {
			// fcms アイキャッチ
			this.eyecatchsMethods.asc='sort'
			fcms.fetchList(this.setFcms,2,1,this.eyecatchsMethods);

			// fcms 店の並び順
			this.shopFieldMethods.limit1='0'
			this.shopFieldMethods.limit2='1'
			fcms.fetchList(this.setFcms,2,3,this.shopFieldMethods);

		}
	}
</script>
<style scoped>
	@import "~@/styles/style.css";

	.v-text-field > .v-input__control > .v-input__slot::before{
		border-color: rgba(0, 0, 0, 0);
	}
	.btn-reserve button{
		width:100%;
		padding:32px;
	}
	.border-none {
		border:none!Important;
	}
	.card-pa0 {
		padding:0!important;
	}
	.icon-map i {
		color:#e60044;
		font-size:24px;
	}
	.v-text-field--filled {
		border-radius:0;
	}
	.select-menu {
		border-radius:0!important;
		height: 56px;
		overflow: hidden;
	}
	.btn-reserve button,
	.btn-reserve a
	{
		height: auto!important;
		padding-top: 24px!important;
		padding-bottom: 24px!Important;
		font-weight:bold;
	}
	.btn-register{
		text-align:center;
	}
	.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
		color: rgba(0, 0, 0, 0.87);
	}

	@media (max-width: 599px) {
		.sp-hide {
			display: none;
		}
	}
  .abrid-add {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 6em;
  }
  .abrid-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    height: 8em;
  }
</style>
