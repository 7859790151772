import "babel-polyfill";
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const createStore = () => {
	return new Vuex.Store({
	state: function () {
		return {
			cartItems:[],
			orderInfo:{
				beforePath:null, 			// カート前のpath

				cartUserPanel:0, 			// 申込情報設定されたか(1 or 0)
				memberType:null, 			// 会員種別
				name:null, 					// お名前
				furigana:null, 				// ふりがな
				birthday:null, 				// 生年月日
				sex:null, 					// 性別
				zip:null, 					// 郵便番号
				city:null, 					// 都道府県名
				address1:null, 				// 市区町村
				address2:null, 				// 以下住所
				tel:null, 					// 電話番号
				sendtoName:null, 			// 配送先
				usermail:null, 				// メールアドレス
				login_id:null, 				// ログインID

				cartDeliveryPanel:0, 		// 配送先が設定されたか(1 or 0)
				d_name:null, 				// 配送先 お名前
				d_furigana:null, 			// 配送先 ふりがな
				d_birthday:null, 			// 配送先 生年月日
				d_sex:null, 				// 配送先 性別
				d_zip:null, 				// 配送先 郵便番号
				d_city:null, 				// 配送先 都道府県名
				d_address1:null, 			// 配送先 市区町村
				d_address2:null, 			// 配送先 以下住所
				d_tel:null, 				// 配送先 電話番号
				note:null, 					// 備考
				deliveryRito:null, 			// 離島

				cartPaymentPanel:0, 		// 支払い方法が設定されたか(1 or 0)
				couponcode:null,			// クーポンコード
				point:null,					// ポイント
				paymentType:null,			// 支払い方法
				payName:null,				// 支払い方法名称
				credit_token_id:null,		// クレジット決済トークン
				customer_payment_id:null,	// stripeID

			},
			token: null,
		}
	},
	mutations: {
		// トークン
		SET_TOKEN(state, data) {
			state.token = data;
		},
		// カートアイテム
		ADD_CART_ITEMS(state, data) {
			state.cartItems = data;
		},
		// 注文情報
		SET_ORDER_INFO(state, data) {
			state.orderInfo = data;
		}
	},
	actions: {},
	modules: {},
	plugins: [createPersistedState()]
	});
};

export default createStore;
